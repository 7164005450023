$navbar-dark-color: $white;
$navbar-dark-active-color: $navbar-dark-color;


div.navbar-sidebar-margin {
    overflow: visible;
    padding: 1rem;

    .logo {
        height: 2.8125rem;
        width: 2.8125rem;
    }
}

.sidebar-container {
    @extend .d-flex;
    @extend .align-items-stretch;
    @extend .flex-column;
    @extend .flex-lg-row;
    @extend .flex-shrink-1;
    @extend .flex-grow-1;
    min-height: 0;

    >* {
        min-width: 0;
    }

    .navbar-sidebar {
        max-width: 100%;
        overflow-y: visible;

        @include media-breakpoint-up(lg) {
            flex-grow: 2;
            flex-shrink: 1;
            min-width: 16.5rem;
            overflow-y: auto;
            padding: 1rem;
        }

        @include media-breakpoint-down(md) {
            display: block;
            padding: 0;
        }

        .navbar-brand {
            font-size: 1.125rem;
            font-weight: bold;

            @include media-breakpoint-down(md) {
                font-weight: normal;
                margin-left: 1rem;

                img {
                    height: 1.75rem;
                }
            }

            @include media-breakpoint-up(lg) {
                margin: 0.9375rem;
                margin-bottom: 1.875rem;

                img {
                    height: 2.375rem;
                    width: 2.375rem;
                }
            }
        }

        .nav {
            flex-wrap: nowrap;
        }

        .nav-footer {
            border-top: fade-out($black, 0.9) solid 0.0625rem;

            .user-identity-card {
                small {
                    font-size: 0.6875rem;
                    font-weight: 300;
                }

                strong {

                    font-size: 0.8125rem;
                    font-weight: bold;
                }
            }
        }

        .navbar-nav {
            .nav-item {
                font-size: 1rem;
                margin: 0.3125rem 0;

                &:first-child {
                    margin-top: 0;
                }
                &::last-child {
                    margin-bottom: 0;
                }

                @include media-breakpoint-down(md) {
                    margin: 0.3125rem 0.5rem;
                }

                @include media-breakpoint-down(md) {
                    &:first-child {
                        margin-top: 1rem;
                    }

                    &:last-child {
                        margin-bottom: 1rem;
                    }
                }

                ul.nav-link-submenu {
                    font-size: 0.875rem;
                    list-style: none;
                    padding: 0;

                    li {
                        padding: 0.375rem 1rem;
                    }
                }

                .nav-link {
                    padding: 0.125rem 1rem;

                    @include media-breakpoint-down(md) {
                        padding: 0.125rem 0.5rem;
                    }

                    .nav-link-icon {
                        font-size: 1.375rem;
                        padding-right: 0.9rem;

                        i {
                            height: 1.781rem;
                        }
                    }
                }

                .down-icon {
                    color: fade-out($black, 0.75);
                    transform: rotate3d(0, 0, 0, 180deg);
                    transition: 0.5s;
                }

                &.active,
                &:hover,
                &.open {
                    background: fade-out($black, 0.75);
                    border-radius: $border-radius;

                    &.open {
                        .down-icon {
                            transform: rotate3d(1, 0, 0, 180deg);
                        }
                    }

                    &,
                    a {
                        color: $white;
                    }
                }
            }
        }

        .user-identity-card {
            small {
                font-size: 0.8125rem;
                font-weight: 300;
            }

            strong {

                font-size: 1rem;
                font-weight: bold;
            }
        }

        .navbar-collapse {
            align-items: inherit;
            flex-grow: inherit;
        }
    }
}
