$nav-tabs-card-background: #f8f8f8;

.nav {
    &.nav-scroll {
        flex-shrink: 0;
        flex-wrap: nowrap;
        white-space: nowrap;

        @mixin scroll-icon {
            align-items: center;
            background: $white;
            color: transparent;
            cursor: pointer;
            display: flex !important;
            position: sticky;
            transition: 0.5s;
            width: 0;
            z-index: 1;

            .icon-container {
                margin-bottom: auto;
                margin-top: auto;

                i {
                    padding: 0 1rem;
                }
            }
        }

        @mixin allow-scrolling {
            overflow-x: auto;
            overflow-y: hidden;

            .scroll-arrow-left {
                @include scroll-icon();
                left: 0;
                transform: scaleX(0) translateX(-50%);

                .icon-container i {
                    border-right: solid 0.125rem transparent;
                }
            }

            .scroll-arrow-right {
                @include scroll-icon();
                right: 0;
                transform: scaleX(0) translateX(50%);

                .icon-container i {
                    border-left: solid 0.125rem transparent;
                }
            }

            &.can-scroll-left .scroll-arrow-left,
            &.can-scroll-right .scroll-arrow-right {
                color: fade-out($black, 0.8);
                transform: scaleX(1) translateX(0);
                width: 2.5rem;

                &.scroll-arrow-left {
                    .icon-container i {
                        border-right: solid 0.125rem fade-out($black, 0.8);
                    }
                }

                &.scroll-arrow-right {
                    .icon-container i {
                        border-left: solid 0.125rem fade-out($black, 0.8);
                    }
                }
            }
        }

        @include media-breakpoint-down(md) {
            @include allow-scrolling();
        }

        &.nav-scroll-always {
            @include allow-scrolling();
        }

        .scroll-arrow-left,
        .scroll-arrow-right {
            display: none;
        }
    }

    .nav-pills {
        .nav-item {
            .nav-link {
                border-radius: 0.1875rem;
                font-size: 0.875rem;
                padding: 0.5rem 1rem;

                &:not(.active) {
                    color: #999;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }

        .nav-link {
            word-break: break-all;
        }
    }

    // &.nav-tabs {
    //     &.nav-tabs-card {
    //         background: $nav-tabs-card-background;
    //         border: solid 0.0625rem $card-border-colour;
    //         border-top-left-radius: $border-radius;
    //         border-top-right-radius: $border-radius;

    //         .scroll-arrow-left,
    //         .scroll-arrow-right {
    //             background: $nav-tabs-card-background;
    //         }
    //     }

    //     .nav-item {
    //         &:not(:first-child) {
    //             background-image: linear-gradient(to left, $card-border-colour 0%, $card-border-colour 100%) !important;
    //             background-position: left !important;
    //             background-repeat: no-repeat !important;
    //             background-size: 0.0625rem 50% !important;
    //         }

    //         &.show {
    //             .nav-link {
    //                 background-color: transparent;
    //                 border-color: transparent;
    //                 color: $black;
    //             }
    //         }

    //         .nav-link {
    //             color: #999;
    //             padding: 1rem 1.5rem;

    //             &.disabled {
    //                 cursor: not-allowed;
    //             }

    //             &:hover,
    //             &:focus {
    //                 &:not(.active) {
    //                     border-color: transparent;

    //                     &:not(.disabled) {
    //                         color: $black;
    //                     }
    //                 }
    //             }

    //             .fas,
    //             .fab,
    //             .fal,
    //             .far,
    //             .fa {
    //                 margin-right: 0.25rem;
    //             }

    //             &.active {
    //                 color: $black;
    //                 margin-left: -0.0625rem;
    //                 margin-right: -0.0625rem;
    //                 margin-top: -0.0625rem;
    //                 padding-top: 1.0625rem;

    //                 .fas,
    //                 .fab,
    //                 .fal,
    //                 .far,
    //                 .fa {
    //                     color: $primary;
    //                 }
    //             }
    //         }
    //     }
    // }
}
