@each $color,
$value in $theme-colors {
    $colours-border-width: 0.1875rem;

    .bg-#{$color}-dark {
        background: mix($black, $value, 10%);
    }

    .border-bottom-#{$color} {
        border-bottom: solid $colours-border-width $value;
    }

    .border-top-#{$color} {
        border-top: solid $colours-border-width $value;
    }

    .border-left-#{$color} {
        border-left: solid $colours-border-width $value;
    }

    .border-right-#{$color} {
        border-right: solid $colours-border-width $value;
    }
}

.text-black {
    color: $black;
}

.bg-text {
    background: $body-color;
}
