$table-bg: $white;
$table-accent-bg: #f9f9f9;
$table-hover-bg: #dbeff8;

table.table {
    thead {
        th {
            background: $table-bg;
            color: #aaa;
            font-weight: 400;
            position: sticky;
            text-transform: uppercase;
            //1 rem + 1px to handle the border
            top: -1.0625rem;

            .highlighted-column {
                color: #222;
                font-weight: 600;
            }
        }
    }

    &.table-flush {
        margin-bottom: 0;
    }

    .highlighted-column {
        font-weight: 600;
    }

    &.table-dark thead th {
        background: $table-dark-bg;
    }
}
