.progress {
    border: solid $progress-border-width rgba(0, 0, 0, 0.12);

    &.progress-thin {
        border-radius: 0.1875rem;
        height: 0.375rem;
        overflow: visible;
        position: relative;

        .progress-bar {
            border: solid $progress-border-width rgba(0, 0, 0, 0.12);
            border-radius: 0.1875rem;
            box-sizing: content-box;
            margin: -$progress-border-width;
            padding-right: $progress-border-width;
        }
    }
}
