.main-container {
    height: 100vh;
    min-height: 100%;
    max-height: 100%;
    @extend .d-flex;
    @extend .flex-column;
}

.page {
    max-width: 87.5rem;

    @include media-breakpoint-down(md) {
        margin-bottom: 5.5rem;
    }

    &.row {
        @include media-breakpoint-down(lg) {
            @include media-breakpoint-up(lg) {
                flex-direction: column-reverse;
                margin-bottom: 1rem;
            }
        }
    }

    >* {
        min-width: 0;
    }
}
