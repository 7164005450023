@mixin animated-underline {
    transition: 0.2s;
    background-repeat: no-repeat !important;
    background-position: bottom !important;
    background-size: 0 0.125rem !important;

    &:focus,
    &:active {
        @include animated-underline-in;
    }
}

@mixin animated-underline-in {
    background-size: 100% 0.1875rem !important;
    background-image: linear-gradient(to bottom, $primary 0%, $primary 100%) !important;

    &.is-invalid {
        background-image: linear-gradient(to bottom, $danger 0%, $danger 100%) !important;
    }

    &.is-valid {
        background-image: linear-gradient(to bottom, $success 0%, $success 100%) !important;
    }

    &[readonly],
    &[disabled] {
        background-size: 0 0 !important;
    }
}
