@keyframes pulse {
    0% {
        background-color: rgba(165, 165, 165, 0.1);
    }
    50% {
        background-color: rgba(165, 165, 165, 0.3);
    }
    100% {
        background-color: rgba(165, 165, 165, 0.1);
    }
}

.text-input__loading--line {
    height: 10px;

    &.big {
        height: 25px;
    }

    animation: pulse 1.5s infinite ease-in-out;

    @mixin nth-children($points...) {
        @each $point in $points {
            &:nth-child(#{$point}) {
                @content;
            }
        }
    }

    @include nth-children(1, 5, 9) {
        min-width: 100px;
        max-width: 200px;
    }
    @include nth-children(2, 6, 10) {
        max-width: 250px;
    }
    @include nth-children(3, 7) {
        max-width: 50px;
    }
    @include nth-children(4, 8) {
        max-width: 100px;
    }
}
