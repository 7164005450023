.navbar {
    .navbar-toggler {
        border-width: 0 !important;
        margin-right: 0;
        outline: none;
        padding-bottom: 0.75rem;
        padding-top: 0.8125rem;

        &:not(.collapsed) {
            .icon-bar {
                &:first-child {
                    transform: translateY(0.5625rem) rotate(45deg);
                }

                &:last-child {
                    transform: translateY(-0.5625rem) rotate(-45deg);
                }

                &:not(:first-child):not(:last-child) {
                    opacity: 0;
                }
            }
        }

        .icon-bar {
            background: $white;
            height: 0.1875rem;
            margin-bottom: 0.375rem;
            transition: 0.25s ease-in-out;
            width: 1.75rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .nav-button {
        border-radius: 0.1875rem;
        display: block;
        font-size: 1.4375rem;
        margin: 0.25rem 0;
        padding: 0.25rem 0.5rem;

        &:hover,
        &.active,
        &:focus {
            background: fade-out($black, 0.7);
        }

        .badge-overlay-container {
            left: 1rem;
            top: -0.5rem;

            .badge {
                font-size: 0.625rem;
            }
        }
    }

    &.text-light .nav-button {
        color: $white;
    }

    &.text-dark .nav-button {
        color: $black;
    }
}
