// no idea where i got this color from - it's a random color
$primary: #2c3e50; // #47b6f7 !default; // rgb(252, 151, 193) !default;

// the dark primary makes the links too dark
$link-color: #47b6f7; // #0b5d90;

// this is actually just setting the seconday color BACK TO THE ORIGINAL color
// the default color for bootstrap, as the chubby one is too light to be useful to anyone...
$secondary: #6c757d !default;

$light: #f6f6f6;

$background: #f5f5f5 !default;
$background-alt: #fff !default;

// this is the standard bootstrap 4 input border color
// I only set it here because I want to use it as a theme color
$input-border-color: #b4b4b4;

$theme-colors: (
    'input': $input-border-color,
    'lightfade': rgba(241, 241, 241, 0.5),
    'white': #fff,
);

// bah! this is all a bit too adhoc for my liking, there is likely a better
// way to handle this.
$layoutMainNavWidth: 300px;
$layoutMainNavCollapseWidth: 72px;

$list-group-hover-bg: rgba(255, 238, 187, 0.5);

//variables
@import 'chubby/scss/_variables';

//load bootstrap variables, and parse overridden defaults
@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_mixins';
@import '~bootstrap/scss/_variables';

$pagination-active-bg: color-yiq($primary);
$pagination-active-color: $primary;
$pagination-active-border-color: $primary;

// this is helpful just so I can have similar actions based on the form validation states
// and setting a validation state to 'waiting' is somewhat useful.
// the icon is a transparent gif
// I show 'is-waiting' as a spinner
$form-validation-states: (
    'waiting': (
        'color': $input-border-color,
        'icon':
            url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'),
    ),
);
