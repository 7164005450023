@import '~bootstrap/scss/_functions';
@import '~bootstrap/scss/_variables';

.badge {
    @extend .my-auto;

    font-size: 0.6875rem;
    text-transform: uppercase;

    @each $color,
    $value in $theme-colors {
        &.badge-#{$color} {
            font-weight: normal;
            padding: 0.25rem;
        }
    }

    &.badge-success {
        color: $white;
    }

    &.badge-overlay {
        position: absolute;
        z-index: $zindex-dropdown;
    }
}

.badge-overlay-container {
    height: 0;
    position: relative;
    width: 0;
}

.btn .badge {
    border: solid 0;
}
