$card-deck-margin: 0.5rem;
$card-border-width: 0.0625rem;
$card-border-colour: #e4e4e4;
$card-spacer-x: 1rem;
$card-spacer-y: 1rem;

html body {
    .accordion {
        .card {
            &:last-child:first-child {
                border-bottom: solid $card-border-width $card-border-colour;
            }
        }
    }

    .card-deck {
        @include media-breakpoint-up(sm) {
            margin-bottom: $card-deck-margin * 2;
        }

        @include media-breakpoint-down(xs) {
            &:not(:last-child) {
                .card {
                    margin-bottom: $card-deck-margin * 2;
                }
            }

            &:last-child {
                .card {
                    &:not(:last-child) {
                        margin-bottom: $card-deck-margin * 2;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .card-columns .card {
        margin-bottom: 1.25rem;
    }

    .card {
        background-color: #fefefe;
        border: solid $card-border-width $card-border-colour;
        border-radius: $border-radius;
        box-shadow: 0 0.125rem 0.1875rem 0 fade-out($black, 0.98);

        .card-title,
        .card-header {
            font-size: 1rem;
            font-weight: 500;

            &.card-table-title, &.card-title-table {
                margin: 0.75rem;
            }
        }

        .card-footer {
            &.card-table-footer, &.card-footer-table {
                padding: 0.75rem;
            }
        }

        .card-hero-number {
            font-family: Roboto;
            font-size: 2.5rem;
            font-weight: bold;
        }

        .card-header {
            background: none;
            border-bottom: 0;
        }

        .card-subtitle {
            text-transform: uppercase;
        }

        &.bg-secondary {
            color: $body-color;
        }

        &:not(.text-white) {

            .card-title,
            .card-header {
                color: $primary;
            }
        }

        &.has-tabs {
            border-top: 0;
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            .nav-tabs-card {
                margin-left: -$card-border-width;
                margin-right: -$card-border-width;
            }
        }
    }
}
