.nav.nav-secondary {
    align-items: stretch;

    .nav-item {
        padding: 0 1.5rem;
        white-space: nowrap;

        @include media-breakpoint-down(md) {
            padding: 0 3.25rem;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &,
        a {
            color: #999;
        }

        .active {
            font-weight: bold;
        }

        .nav-link {
            @include animated-underline;

            padding: 1rem 0;

            @include media-breakpoint-down(md) {
                font-size: 1.0625rem;
            }

            &:hover,
            &.active {
                @include animated-underline-in;

                &,
                a {
                    color: $black;
                }
            }
        }
    }
}
