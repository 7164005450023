.btn {
    overflow: hidden;
    position: relative;

    &.btn-success {
        color: $white;

        &:hover {
            color: $white;
        }
    }

    &.btn-outline-success:hover {
        color: $white;
    }

    &.btn-outline-success:active {
        color: $white !important;
    }

    @each $color,
    $value in $theme-colors {
        &.btn-#{$color} {
            background-color: $value;
            font-size: 0.9375rem;
            padding: 0.5rem 0.875rem;

            &.btn-sm {
                padding: 0.25rem 0.4375rem;
            }

            &.btn-xs {
                font-size: 0.75rem;
                padding: 0.125rem 0.4375rem;
            }

            &.btn-lg {
                font-size: 1.25rem;
            }

            &:hover {
                background: mix($black, $value, 20%);
            }

            &.disabled {
                opacity: 0.4;
            }
        }

        &.btn-outline-#{$color} {
            border: 0.0625rem solid $value;
            font-size: 0.9375rem;
            padding: 0.5rem 0.875rem;

            &:hover {
                background: $value;
            }
        }
    }
}


@keyframes button-ripple {
    100% {
        opacity: 0;
        transform: scale(2.5);
    }
}


@each $color,
$value in $theme-colors {
    .btn.btn-#{$color} {
        .button-ripple {
            background: lighten($value, 20%);
            border-radius: 100%;
            display: block;
            opacity: 0.2;
            position: absolute;
            transform: scale(0);

            &.animate {
                animation: button-ripple 0.65s linear;
            }
        }
    }
}
