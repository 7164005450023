@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fade-out {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

html,
body {
    height: 100%;
    background-color: #f5f5f5;
}

code {
    color: #034dbb;
}

.text-pre-wrap {
    white-space: pre-wrap;
}

.text-underline {
    text-decoration: underline;
}

.text-decoration-none {
    text-decoration: none;
}

.text-long-title {
    @extend .text-truncate;
    max-width: 300px;
}

.break-word {
    word-wrap: break-word;
}

.word-break-all {
    word-break: break-all;
}

.opacity-0 {
    opacity: 0 !important;
}
.opacity-5 {
    opacity: 0.5 !important;
}
.opacity-1 {
    opacity: 1 !important;
}

.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.z-3 {
    z-index: 3;
}

.tl-0 {
    top: 0;
    left: 0;
}

.tr-0 {
    top: 0;
    right: 0;
}

.z-100 {
    z-index: 100;
}

.fade-in {
    animation: fade-in 200ms;
}

.fade-out {
    opacity: 0;
    animation: fade-out 200ms;
}

.animation-long {
    animation-duration: 1s;
}

.line-height-input {
    line-height: $input-height;
}

// sets to minimum height, otherwise the auto-size will autosize to less than normal inputs
textarea.form-control {
    min-height: $input-height;
}

.form-control.form-control-editor {
    // forces enough space for the validation icon to appear without jumping
    padding-right: $custom-select-feedback-icon-padding-right;
}

.popover {
    @include media-breakpoint-up(sm) {
        max-width: 400px;
    }
}

.bg {
    background-color: $background;
}

.border-transparent {
    border-color: transparent !important;
}

.border-dashed {
    border-style: dashed;
}

.large {
    font-size: 1.2rem;
}

.xlarge {
    font-size: 1.4rem;
}

.overflow-y {
    overflow-y: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.scale-50 {
    transform: scale(0.5);
    transform-origin: top left;
    width: 200%;
    margin-bottom: -1000%; // no idea why this works
}

.scale-xl-66 {
    @include media-breakpoint-up(lg) {
        transform: scale(0.66);
        width: 151.51%;
    }
}

.layout-main-side {
    @extend .d-none;
    @extend .d-md-flex;
    @extend .vh-100;
    @extend .position-fixed;

    width: $layoutMainNavWidth;
    &.collapsed {
        width: $layoutMainNavCollapseWidth;
    }
}

.layout-main-content {
    @extend .flex-grow-1;

    @include media-breakpoint-up(md) {
        margin-left: $layoutMainNavWidth;

        &.extended {
            margin-left: $layoutMainNavCollapseWidth;
        }

        // this is the viewMode that shows both panels
        &.all {
            // override content-width when extended since it's scaled to 50%
            .content-width {
                max-width: $contentMaxWidth * 2;
            }
        }
    }

    .layout-main-content-inner {
        @include media-breakpoint-down(sm) {
            width: 100% !important;
        }
    }
}

.dropdown-menu.show {
    @extend .shadow;
}

// used for groups of components to style similar to an input
.fake-form-control {
    border: $input-border-width solid $input-border-color;
    background-color: $input-bg;
    @include p(2);

    @if $enable-rounded {
        border-radius: $input-border-radius;
    } @else {
        border-radius: 0;
    }

    @each $state, $data in $form-validation-states {
        $color: map-get($data, color);
        $icon: map-get($data, icon);
        @include form-validation-state-selector($state) {
            border-color: $color;

            @if $enable-validation-icons {
                padding-right: $input-height-inner;
                background-image: escape-svg($icon);
                background-repeat: no-repeat;
                background-position: right $input-height-inner-quarter center;
                background-size: $input-height-inner-half
                    $input-height-inner-half;
            }

            &:focus {
                border-color: $color;
                box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
            }
        }
    }
}

// reset chubby titles that arbitrarily reduce label size
.form-group label {
    font-size: 0.9rem;
}

// removing more chubby styles
div.form-check {
    margin-bottom: 0;
}

// chubby uses an "assumed" font-awesome style, but I'm using only svgs
// I don't want to import the entire font.
.form-check input.form-check-input[type='checkbox'] ~ label:after {
    content: '✓'; // though I have no idea just how compatible this icon is...
}

.input-container-spinner {
    position: relative;
}

.input-container-spinner.is-waiting:after {
    @extend .spinner-border;
    @extend .spinner-border-sm;

    content: '';
    position: absolute;
    right: 0.4rem;
    top: 50%;
    margin-top: -0.5rem;
}

// creates an outline badge type for each color
// also link-colors
@each $color, $value in $theme-colors {
    .badge-outline-#{$color} {
        @include badge-variant($value);
        background: transparent;
        border-color: $value;
        border-style: solid;
        border-width: 0.05rem;
        color: $value;
    }

    .btn-link-#{$color} {
        color: $value;

        @include hover() {
            color: darken($value, 15%);
        }
    }
}

// this is essentitally re-overriding the uppercase forced and normal font-weight of chubby...
.badge {
    @each $color, $value in $theme-colors {
        &.badge-#{$color} {
            text-transform: none;
            font-weight: 400;
            font-size: 90%;
        }
        &.badge-outline-#{$color} {
            text-transform: none;
            font-weight: 400;
            font-size: 90%;
        }
    }
}

.btn-primary {
    color: $white;
}

// this is fixing an issue with chubby button styles
// when a button is disabled and is hovered
.btn {
    @each $color, $value in $theme-colors {
        &.btn-#{$color}.disabled:hover,
        &.btn-#{$color}[disabled]:hover {
            background-color: $value;
            cursor: default;
        }

        // fix for outline colors on btn-sm
        &.btn-outline-#{$color}.btn-sm {
            padding: 0.25rem 0.4375rem;
        }
    }
}

.btn-link:focus.btn-no-focus,
.btn-link.focus.btn-no-focus {
    text-decoration: none;

    &:hover,
    &.hover {
        text-decoration: underline;
    }
}

.btn.btn-no-focus,
.btn.btn-no-focus.btn-link {
    box-shadow: none;
    outline: none;
}

button.no-focus {
    outline: none;
}

.list-group {
    &.list-group-flush {
        .list-group-item-action:not(.active) {
            background-color: transparent;

            &:hover {
                background-color: rgba(0, 0, 0, 0.09);
            }
        }
    }

    .list-group-item.disabled {
        background-color: $gray-200;
    }
}

.bg-disabled {
    background-color: $gray-200;
}

// this is fixing the chubby close button on modals...
.modal .modal-dialog .modal-content .modal-header .close {
    font-size: 2rem;
    line-height: 1rem;
    @include pl(2);
    @include py(0);
    @include pr(0);
    @include m(0);
}

.dropdown-toggle.no-icon:after {
    content: none;
}

// Fix for FontAwesome icons (so they are the same width (for lg))
.fa-lg[class^='svg-inline--'] {
    width: 1.05em !important;
}

.alert-body p {
    @include mb(1);
}

.top-nav-container {
    // bit of a cheat but whatever
    min-height: 54px;
}

// bit of a hack, but whatever, the styles are better
.pagination-button-group {
    .page-item.active .page-link {
        cursor: pointer;
    }
}

.pagination .page-item.active .page-link {
    background-color: $primary;
    color: color-yiq($primary);

    &:hover {
        background-color: lighten($primary, 90%);
        color: color-yiq(lighten($primary, 90%));
        z-index: 3;
    }
}

.card-rounded-bottom {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    @extend .rounded-bottom;
}

.btn-popover-delete {
    opacity: 0;

    @media (hover: none) {
        opacity: 1 !important;
    }

    &:hover {
        color: $danger;
    }
}

.section-list-item:hover .btn-popover-delete,
.variant-tab-container:hover .btn-popover-delete {
    opacity: 1;
}
.import-export-reset-button .btn-popover-delete {
    opacity: 1 !important;
    color: $link-color !important;
}

.text-unset {
    color: unset;
}

.bg-unset {
    background-color: unset;
}

.section-list-item-container.active {
    background-color: $list-group-hover-bg;
}

.variant-before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 10px;
    margin: 0;
}

// content modifications
[id^='c-'].grid-content {
    &.hover::before {
        z-index: 1000;
    }

    &.active::before {
        z-index: 1000;
    }
}

[id^='c-'] {
    position: relative;

    &.hover::before {
        @extend .variant-before;
        background-color: rgba(204, 204, 204, 0.5);
    }
    &.active::before {
        @extend .variant-before;
        background-color: $list-group-hover-bg;
    }
}

.dropzone-container {
    &:hover,
    &.drag-hover {
        cursor: pointer;
        border-color: $input-color;
        border: $input-border-color $input-border-width solid;
        box-shadow: 0 0 0 $input-focus-width rgba($input-color, 0.25);
    }
}

// because i overuse list-group-item-action particularly with drags, the below styles have been helpful
.list-group-item-inner-button {
    @extend .text-left;
    @extend .no-focus;
    @extend .border-0;
    @include py(3);
    @include pl(0);

    @extend .text-unset;
    @extend .bg-unset;
}

.list-group-item-action-fake {
    @extend .list-group-item;
    @extend .list-group-item-action;
    @extend .border-left-0;
    @extend .border-right-0;
    @include p(0);
}

// redactorx
.rx-container {
    @extend .border-input;
}

// tinymce
.tox.tox-tinymce {
    // @extend .h-100;
    // @extend .form-control;
    // padding-right: $input-height-inner;
    @extend .rounded;

    // animation: fade-in 200ms;
    // outline: none;

    // this just makes selecting the p tag a little easier
    p {
        margin-bottom: 0;
        padding-bottom: $paragraph-margin-bottom;
    }

    .tox-toolbar__primary {
        background: none;
    }
}

// tiny fake form stuff
.tiny-container {
    position: relative;

    .tiny-container-after.is-waiting {
        position: absolute;
        margin-right: 0.4rem;
        top: 0;
        right: 0;
        z-index: 1000;
        height: 100%;
        display: flex;

        &:after {
            content: '';
            margin: auto 0;
            @extend .spinner-border;
            @extend .spinner-border-sm;
        }
    }
}

.main-nav {
    .nav-icon-button {
        font-size: 1.15em;
    }

    .nav-icon-button,
    .list-group-item-action,
    .list-group-item-action-fake {
        color: $light;
        @extend .text-white;
        @extend .rounded;
        @extend .border-transparent;

        &:hover,
        &.active,
        &:focus {
            background-color: rgba(0, 0, 0, 0.3) !important;
            color: $light;
        }
    }
}
