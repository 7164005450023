$drawer-animation-duration: 0.2s;
$drawer-animation-style: cubic-bezier(0.4, 0, 0.2, 1);

@mixin drawer {
    background: transparent;
    bottom: 0;
    display: block;
    left: 0;
    max-height: 100vh;
    max-width: 100vw;
    outline: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: $drawer-animation-duration $drawer-animation-style;
    z-index: $zindex-modal-backdrop;

    .navbar-sidebar-margin {
        background: mix($black, $ch-drawer, 5%);
    }

    .nav-button {

        &:hover,
        &.active {
            background: rgba(0, 0, 0, 0.2);
        }
    }

    &.show {
        background: rgba(1, 12, 23, 0.35);
        display: block;

        .drawer-body {
            transform: translateX(0) translateY(0) !important;
        }
    }

    .drawer-body {
        background: $ch-drawer;
        max-height: 100vh;
        max-width: 100vw;
        overflow: auto;
        position: relative;
        transform: translateX(-100%);
        transition: $drawer-animation-duration $drawer-animation-style;
        z-index: $zindex-modal;

        &.drawer-body-fullscreen-mobile {
            @include media-breakpoint-down(md) {
                height: 100%;
                width: 100%;
            }
        }


        .secondary-nav-button {
            border-radius: 0.1875rem;
            color: #111;
            display: flex;
            font-size: 1.4375rem;
            margin: 0.25rem;
            padding: 0.25rem 0.5rem;
            transition: 0.25s ease-in-out;

            &:hover,
            &.active {
                background: rgba(0, 0, 0, 0.1);
            }
        }

        > .flex-grow-1 {
            max-height: 100%;
            overflow-y: auto;
        }
    }

    &.drawer-left {
        .drawer-body {
            height: 100%;
            margin-right: auto;
            transform: translateX(-100%);
            width: 36.0625rem;
        }
    }

    &.drawer-right {
        .drawer-body {
            height: 100%;
            margin-left: auto;
            transform: translateX(100%);
            width: 36.0625rem;
        }
    }

    &.drawer-top {
        .drawer-body {
            margin-bottom: auto;
            transform: translateY(-100%);
            width: 100%;
        }
    }

    &.drawer-bottom {
        .drawer-body {
            margin-top: auto;
            transform: translateY(100%);
            width: 100%;
        }}
}


.drawer {
    @include drawer();
}

.drawer-mobile {
    @include media-breakpoint-down(md) {
        @include drawer();
    }
}

.drawer-desktop {
    @include media-breakpoint-up(lg) {
        @include drawer();
    }
}
