//CareerHub colours
$ch-blue: #199cd9;
$ch-secondary: #e0e0e0;
$ch-success: #55c995;
$ch-danger: #ed6464;
$ch-warning: #f0c339;
$ch-info: #5ba4c6;
$ch-background: #f1f1f1;
$ch-drawer: #fcfcfc;
$ch-actions: #f9f9f9;
$ch-dark: #565656;

//Bootstrap overrides
$primary: $ch-blue !default;
$secondary: $ch-secondary !default;
$success: $ch-success !default;
$info: $ch-info !default;
$warning: $ch-warning !default;
$danger: $ch-danger !default;
$light: $ch-background !default;
$dark: $ch-dark !default;

$border-radius: 0.1875rem;
$border-radius-sm: $border-radius;
$border-radius-lg: $border-radius;
$font-size-base: 0.875rem;
$input-btn-border-width: 0;
$input-height-border: 7px;
$grid-gutter-width: 1rem;
$body-color: #222;
$breadcrumb-divider: quote('>');

$progress-border-width: 0.0625rem;
