$input-border-width: 0.0625rem;
$input-border-color: #b4b4b4;
$input-border-radius: 0.1875rem;

$input-bg: #fefefe;
$input-color: $black;


small.form-text,
small.invalid-feedback {
    font-size: 0.75rem;
    margin-top: 0;
    padding-top: 0.5rem;
}

input,
select {

    &.form-control,
    &.custom-select {
        padding: 0.5rem 0.75rem;

        &:invalid {
            outline: none;

            &:focus {
                @extend .is-invalid;
            }
        }

        &select {
            padding-bottom: 0.5625rem;
            padding-top: 0.5625rem;
        }

        &::placeholder,
        &[placeholder] {
            font-size: 0.875rem;
        }
    }
}

input.form-control,
textarea.form-control {
    &:not(:disabled):not(.disabled):not([disabled]):not(:read-only):not(.readonly):not([readonly]) {
        &:hover {
            background-color: $white;
            border: 0.0625rem solid $primary;
            box-shadow: 0 0 0 0.11rem fade-out($primary, 0.75);
        }

        &:focus {
            border-color: $primary;

            &:hover {
                box-shadow: 0 0 0 0.2rem fade-out($primary, 0.75);
            }
        }
    }
}

input.form-control.is-invalid,
textarea.form-control.is-invalid {
    &:not(:disabled):not(.disabled):not([disabled]):not(:read-only):not(.readonly):not([readonly]) {
        &:hover {
            background-color: $white;
            border: 0.0625rem solid $danger;
            box-shadow: 0 0 0 0.11rem fade-out($danger, 0.75);
        }

        &:focus {
            border-color: $danger;

            &:hover {
                box-shadow: 0 0 0 0.2rem fade-out($danger, 0.75);
            }
        }
    }
}

input.form-control.is-valid,
textarea.form-control.is-valid {
    &:not(:disabled):not(.disabled):not([disabled]):not(:read-only):not(.readonly):not([readonly]) {
        &:hover {
            background-color: $white;
            border: 0.0625rem solid $success;
            box-shadow: 0 0 0 0.11rem fade-out($success, 0.75);
        }

        &:focus {
            border-color: $success;

            &:hover {
                box-shadow: 0 0 0 0.2rem fade-out($success, 0.75);
            }
        }
    }
}


.form-group {
    label {
        color: #222;
        font-size: 0.8125rem;
    }
}

div.form-check {
    margin-bottom: 0.25rem;
    padding-left: 0;
}


@mixin checkbox-or-radio {
    position: absolute;
    opacity: 0;

    ~label {
        cursor: pointer;
        padding: 0;
        padding-left: 1.875rem;
        position: relative;

        &::before {
            border: solid 0.0625rem $input-border-color;
            content: '';
            display: inline-block;
            height: 1.25rem;
            left: 0;
            position: absolute;
            top: 0;
            transition: box-shadow 0.25s ease-in-out, border-color 0.25s ease-in-out;
            vertical-align: text-bottom;
            width: 1.25rem;
        }

        &::after {
            background: $primary;
            display: inline-block;
            height: 1.25rem;
            left: 0;
            line-height: 1;
            padding: 0.25rem;
            position: absolute;
            top: 0;
            transform: scale(0);
            transition: transform ease-in-out 0.15s;
            width: 1.25rem;
        }
    }

    input:disabled~label {
        cursor: not-allowed;
    }

    &:not(:disabled):hover~label::after {
        transform: scale(0.1875);
    }

    &:focus~label::before {
        box-shadow: 0 0 0 0.1875rem fade-out($primary, 0.2);
    }

    &:disabled~label {
        color: #b8b8b8;
        cursor: auto;

        &::before {
            border-color: #ddd;
            box-shadow: none;
        }

        &::after {
            background: #ddd;
            box-shadow: none;
        }
    }
}


label.custom-control-label {
    &::before {
        background-color: fade-out($primary, 0.8);
    }

    &::after {
        //fix switches
        left: -2.25rem;
    }
}
