$base-height: 1.3rem;
$base-spacing: 0.2rem;


$base-width: $base-height * 2;

.form-check-switch {
    margin-bottom: 0.25rem;
    position: relative;

    @each $color,
    $value in $theme-colors {
        &.form-check-switch-#{$color} {

            input[type=checkbox],
            input[type=radio] {
                &.form-check-input:checked+label {
                    &::before {
                        background: $value;
                    }
                }
            }
        }
    }

    input[type=checkbox],
    input[type=radio] {
        &.form-check-input {
            height: 0;
            margin: 0 !important;
            visibility: hidden;
            width: 0;

            &:checked+label {
                &::before {
                    background: $primary;
                }

                &::after {
                    left: #{$base-width - ($base-spacing / 2)};
                    transform: translateX(-100%);
                }
            }

            &:disabled+label {
                cursor: not-allowed;
            }
        }
    }

    label.form-check-label {
        cursor: pointer;
        text-indent: $base-width + ($base-spacing * 2);

        &::before {
            background: $secondary;
            border-radius: $base-height;
            content: '';
            display: block;
            height: $base-height;
            left: 0;
            position: absolute;
            top: 0;
            width: $base-width;
        }

        &:active::after {
            width: $base-height + ($base-spacing * 2);
        }

        &::after {
            background: #fff;
            border-radius: $base-height - $base-spacing;
            content: '';
            height: $base-height - $base-spacing;
            left: $base-spacing / 2;
            position: absolute;
            top: $base-spacing / 2;
            transition: transform 0.3s, left 0.3s, width 0.3s;
            width: $base-height - $base-spacing;
        }
    }
}
